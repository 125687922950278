import { Card, CardImg, CardImgOverlay } from 'reactstrap';

const HomepageLocationCard = props => {
  const { title, note, linkUrl, imgUrl, disabled } = props;
  return (
    <a href={linkUrl}>
      <Card className={disabled ? "homepageLocationCardDisabled" : "homepageLocationCard"}>
        <CardImg className="homepageLocationCardImg" width="100%" src={imgUrl}/>
        <CardImgOverlay className="homepageLocationCardOverlay">
          <h3 className="homepageLocationCardTitle">{title}</h3>
          <p className="homepageLocationCardSub">and surrounding areas</p>
          <p className="homepageLocationCardNote">{note}</p>
        </CardImgOverlay>
      </Card>
      <style global jsx>{`
        .homepageLocationCard {
          background-color: black;
          border: none;
        }
        .homepageLocationCardDisabled {
          background-color: #d6d6d6;
          border: none;
        }
        .homepageLocationCardOverlay {
          color: white;
        }
        .homepageLocationCard .homepageLocationCardImg {
          opacity: 0.8;
        }
        .homepageLocationCardDisabled .homepageLocationCardImg {
          opacity: 0.2;
        }
        .homepageLocationCardTitle {
          margin-top: 0.4em;
          margin-bottom: 0;
        }
        .homepageLocationCardSub {
          font-size: 0.8em;
        }
        .homepageLocationCardNote {
          margin-top: 1.5em;
          font-size: 1.2em;
        }
      `}</style>
    </a>
  );
};

export default HomepageLocationCard;
