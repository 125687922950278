import { useState } from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Button, InputGroup, InputGroupAddon, Input, CardColumns, Card, CardImg, CardImgOverlay } from 'reactstrap';
import Link from 'next/link';
import Head from 'next/head';

import Layout from '../components/Layout';
import Logo from '../components/Logo';
import HomepageLocationCard from '../components/HomepageLocationCard'

const mapStateToProps = ({ metros }) => ({ 
  metrosArray: metros.array,
});

const Home = props => {
  const { metrosArray } = props;
  const [ selectedId, setSelectedId ] = useState(metrosArray[0] ? metrosArray[0].id : "");
  const [ selectedDisplay, setSelectedDisplay ] = useState(metrosArray[0] ? metrosArray[0].nameExpanded : "");

  const handleMetroInputChange = e => {
    setSelectedDisplay(e.target.value);
    setSelectedId(metrosArray[e.target.options.selectedIndex].id);
  };

  return (
    
      <Layout>
        <Head>
          <title>Wedding Venues - Explore Real Photos - MarriedThere.com</title>
          <meta name="description" content="Discover the perfect wedding venue through real wedding photography."/>
        </Head>

        <Container className="homepageHeroContainerMobile hideAboveMd">
          <Row className="homepageHeaderMobile">
            <Col>
              <Logo/>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src="/static/cover.jpg" className="homepageCoverMobile"/>
            </Col>
          </Row>
          <Row>
            <Col className="homepageHeroAreaMobile">
              <h2 className="homepageHero1Mobile">
                Enjoy finding your perfect wedding venue
              </h2>
              <h3 className="homepageHero3Mobile">Discover exciting venues - by browsing through real wedding photos from top wedding photographers.</h3>
              <InputGroup>
                <Input bsSize="lg" type="select" value={selectedDisplay} onChange={handleMetroInputChange}>
                  {metrosArray.map(metro => (
                    <option key={metro.id}>
                      {metro.nameExpanded}
                    </option>
                  ))}
                </Input>
                <InputGroupAddon addonType="append">
                  <Link href="/wedding-venues/[location]" as={`/wedding-venues/${selectedId}`}>
                    <Button size="lg" color="primary">Discover</Button>
                  </Link>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          <Row className="homepageImgCaptionAreaMobile">
            <Col className="homepageImgCaptionAreaMobileText">
              <p className="homepageImgCaptionAreaMobileVenue">Featured Above: Riverway Clubhouse</p>
              <p className="homepageImgCaptionAreaMobileCredit">Tanya & Faisal by Mathias Fast Photography</p>
            </Col>
          </Row>
        </Container>

        <Container className="homepageHeroContainer hideBelowMd">
          <Row>
            <Col>
              <img src="/static/cover.jpg" className="homepageCover"/>
            </Col>
          </Row>
          <Row className="homepageHeader">
            <Col>
              <Logo/>
            </Col>
          </Row>
          <Row>
            <Col className="homepageHeroArea">
              <h2 className="homepageHero1">
                Enjoy finding your <br/>
                perfect wedding venue
              </h2>
              <p className="homepageHero2">Searching for a wedding venue can be tedious.</p>
              <h3 className="homepageHero3">Discover exciting venues - by browsing through real wedding photos from top wedding photographers.</h3>
              <InputGroup>
                <Input bsSize="lg" type="select" value={selectedDisplay} onChange={handleMetroInputChange}>
                  {metrosArray.map(metro => (
                    <option key={metro.id}>
                      {metro.nameExpanded}
                    </option>
                  ))}
                </Input>
                <InputGroupAddon addonType="append">
                  <Link href="/wedding-venues/[location]" as={`/wedding-venues/${selectedId}`}>
                    <Button size="lg" color="primary">Discover Venues</Button>
                  </Link>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          <Row className="homepageImgCaptionArea">
            <Col>
              <div className="homepageImgCaptionAreaText">
                <p>Riverway Clubhouse Couple</p>
                <p>Tanya + Faisal</p>
                <p>Wedding Photography: Mathias Fast Photography</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="homepagePane2Container">
          <Row>
            <Col>
              <h2 className="homepagePane2Header">Get a better picture of potential venues</h2>
              <p className="homepagePane2Sub">Working with wedding photographers, we’re building a beautiful, comprehensive galley of wedding venues</p>
            </Col>
          </Row>
          <Row className="homepagePane2Features">
            <Col xs="4">
              <img src="/static/icon-pictures.png"/>
              <p>See great pictures of each venue</p>
            </Col>
            <Col xs="4">
              <img src="/static/icon-filter.png"/>
              <p>Filter venues by location and features</p>
            </Col>
            <Col xs="4">
              <img src="/static/icon-actions.png"/>
              <p>Save and follow-up with your favourite venues</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="homepagePane2SelectCity">Select your closest city to get started:</h5>
              <CardColumns>
                <HomepageLocationCard 
                  title="Vancouver, BC"
                  note="Over 200 Venues"
                  linkUrl="/wedding-venues/vancouver-bc-area"
                  imgUrl="/static/card-vancouver.jpg"
                />
                <HomepageLocationCard 
                  title="New York, NY"
                  note="Coming Soon"
                  imgUrl="/static/card-new-york.jpg"
                  disabled
                />
                <HomepageLocationCard 
                  title="Los Angeles, CA"
                  note="Coming Soon"
                  imgUrl="/static/card-los-angeles.jpg"
                  disabled
                />
              </CardColumns>
            </Col>
          </Row>
        </Container>

        <style jsx>{`
        p {
          margin-bottom: 0;
          line-height: 1em;
        }
        `}</style>
        <style global jsx>{`
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 300;
          src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        .homepageCover {
          width: 100%;
          height: 100vh;
          object-fit: cover;
          position: absolute;
          z-index: -1;
        }
        .homepageHeader {
          padding-left: 3em;
          padding-top: 2em;
        }
        div .homepageHeroContainer {
          width: 100%;
          max-width: 1500px;
          height: 100vh;
          padding: 0;
          overflow: hidden;
          position: relative;
        }
        .homepageHeroArea {
          padding-top: 6em;
          color: #fafafa;
          max-width: 600px;
          margin-left: 3em;
        }
        .homepageHero1 {
          font-family: 'Open Sans', sans-serif;
          font-size: 2.9em;
          font-weight: 300;
          margin-bottom: 15px;
        }
        .homepageHero2 {
          font-family: 'Lato', sans-serif;
          font-size: 1.2em;
        }
        .homepageHero3 {
          font-family: 'Lato', sans-serif;
          font-size: 1.8em;
          margin-top: 15px;
          margin-bottom: 1.5em;
        }
        .homepageImgCaptionArea {
          position: absolute;
          width: 100%;
          right: 0px;
          bottom: 150px;
        }
        .homepageImgCaptionAreaText {
          position: absolute;
          bottom: -100px;
          text-align: right;
          width: 100%;
          padding-right: 4em;
        }
        .homepageImgCaptionAreaText p {
          color: lightgray;
          margin: 0;
        }
        .homepagePane2Container {
          padding: 6em 0;
          text-align: center;
          color: #6f6f6f;
        }
        .homepagePane2Header {
          color: #6bddcc;
        }
        .homepagePane2Sub {
          font-size: 1.2em;
        }
        .homepagePane2Features {
          margin-top: 1.5em;
          margin-bottom: 3em;
          font-size: 1.2em;
        }
        .homepagePane2Features img {
          max-width: 100px;
        }
        .homepagePane2SelectCity {
          margin-bottom: 1em;
        }
        @media (min-width: 1200px) {
          .homepagePane2Container {
            max-width: 960px;
          }
        }
        @media (max-width: 767px) {
          .homepagePane2Container {
            padding-left: 1em;
            padding-right: 1em;
          }
        }
        .homepageHeaderMobile {
          padding: 1em 0 1em 1em;
        }
        .homepageCoverMobile {
          width: 100%;
          margin: 0 -40%;
          width: 166%;
        }
        .homepageHeroAreaMobile {
          padding: 2em 1em;
          text-align: center;
        }
        .homepageHero1Mobile {
          font-family: 'Open Sans', sans-serif;
          color: grey;
        }
        .homepageHero3Mobile {
          font-family: 'Lato', sans-serif;
          font-size: 1.2em;
          color: grey;
          margin: 1em 0 2em 0;
        }
        .homepageImgCaptionAreaMobile p {
          color: lightgrey;
          text-align: right;
        }
        .homepageImgCaptionAreaMobileVenue {
          color: lightgrey;
          text-align: right;
          margin: 0;
        }
        .homepageImgCaptionAreaMobileCredit {
          font-size: 0.9em;
          margin-top: 6px;
        }
        `}</style>
      </Layout>
  )
};

export default connect(mapStateToProps, null)(Home);
